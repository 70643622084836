function addParamsToEnrollmentUrl(enrollmentUrl) {
  const params = new URLSearchParams(window.location.search);
  const nextUrl = new URL(enrollmentUrl);

  if (params !== null) {
    params.forEach((value, key) => {
      nextUrl.searchParams.append(key, value);
    });
  }

  return nextUrl.toString();
}

export default addParamsToEnrollmentUrl;
