import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { colorify } from 'lottie-colorify';
import LoadingLottie from '../../assets/lotties/loading.json';

export default function Loading(props) {
  const {
    height,
    width,
  } = props;
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: colorify(['#FF8795', '#FFC3C5', '#FFF3E6'], LoadingLottie),
    });
    return () => anim.destroy();
  }, []);

  return (
    <div
      style={{
        height: height || 48,
        width: width || 48,
      }}
      ref={animationContainer}
    />
  );
}

Loading.defaultProps = {
  height: null,
  width: null,
};

Loading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
