exports.components = {
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-refer-jsx": () => import("./../../../src/pages/refer.jsx" /* webpackChunkName: "component---src-pages-refer-jsx" */),
  "component---src-pages-wait-list-index-jsx": () => import("./../../../src/pages/wait-list/index.jsx" /* webpackChunkName: "component---src-pages-wait-list-index-jsx" */),
  "component---src-pages-wait-list-success-jsx": () => import("./../../../src/pages/wait-list/success.jsx" /* webpackChunkName: "component---src-pages-wait-list-success-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/legalPage.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */)
}

